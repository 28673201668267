.summaryContainer {
    /* margin-top: 20px; */
    padding: 20px 50px 40px 50px;
    background-color: #EAF4E1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.listsContainer {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.bulletsContainer {
    display: flex;
    flex-direction: row;
}

.rightContainer,
.middleContainer {
    width: 35%;
    /* align-self: start; */
}

.leftContainer {
    width: 30%;
    display: flex;
    justify-content: center;
}

.title {
    color: #92D050;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.list {
    background-color: #EAF4E1;
    padding: 20px;
}

.list ul li {
    color: #597F31;
    margin: 10px 0;
    font-size: 16px;
}

.leftContainer img {
    object-fit: contain;
    max-width: 100%;
    padding: 0px 40px;
}

@media (max-width: 800px) {
    .summaryContainer {
      flex-direction: column;
      height: auto;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 30px;
      gap: 0px;
    }

    .title {
        padding-left: 20px;
    }

    .listsContainer {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .leftContainer {
        margin: 0 auto;
    }

    .bulletsContainer {
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .middleContainer,
    .rightContainer {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .titleAndIcon p {
        margin: 0px;
    }

    .list {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .list ul {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    .list ul li:last-child {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}