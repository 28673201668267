.extrasContainer {
    display: flex;
    background: #BACDF6;
    padding: 40px 50px;
    gap: 20px;
    align-items: top;
    justify-content: space-evenly;
}

.leftContainer,
.rightContainer {
    width: 50%;
}

.titleAndIcon {
    display: flex;
    gap: 20px;
    align-items: end;
}

.titleAndIcon p {
    color: #2F5597;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    /* margin: 0; */
}

.titleAndIcon img {
    max-height: 100px;
}

.playDescription p {
    color:#2F5597;
}

.playDescription ul li {
    color:#2F5597;

}

@media (max-width: 800px) {
    .extrasContainer {
      flex-direction: column;
      height: auto;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .rightContainer,
    .leftContainer {
        width: 100%
    }

    .titleAndIcon p {
        margin: 0px;
    }
}