.sectionContainer {
    /* margin-top: 20px; */
    padding: 20px 50px;
    background: #BACDF6;
    display: flex;
    gap: 20px;
    align-items: center;
}

.sectionTitle {
    color: #2F5597;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
}

.sectionDescription,
.hiddenText {
    color: #213C6D;
}

.buttonContainer button {
    background: #2F5597;
    border: none;
    padding: 10px 12px;
    cursor: pointer;
    outline: none;
    /* text-transform: uppercase; */
    font-size: 16px;
    color: #fff;
    border-radius: 999px;
    box-shadow: #213C6D 0 10px 20px -10px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
}

.buttonContainer button:hover {
    box-shadow: rgba(33,60,109,.35) 0 -25px 18px -14px inset,rgba(33,60,109,.25) 0 1px 2px,rgba(33,60,109,.25) 0 2px 4px,rgba(33,60,109,.25) 0 4px 8px,rgba(33,60,109,.25) 0 8px 16px,rgba(33,60,109,.25) 0 16px 32px;
    transform: scale(1.05);
}

.sectionRight {
    width: 50%;
}

.sectionRight img {
    object-fit: contain;
    max-width: 100%;
}

.sectionLeft {
    width: 50%;
}

@media (max-width: 800px) {
    .sectionContainer {
      flex-direction: column;
      height: auto;
      padding: 0px;
      margin-top: 30px;
      padding-bottom: 30px;
    }

    .sectionLeft {
        width: 80%;
        order: 2;
    }

    .sectionRight {
        width: 80%;
        order: 1;
        margin-top: 30px;
    }
}