@import url(https://fonts.googleapis.com/css2?family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


  .App {
  margin: 0;
  padding: 0;
}

.mobile-nav-container {
  display: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: fixed;
  width: 24px;
  height: 20px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #FFC000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad!important;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-bottom: 10px;
  text-decoration: none!important;
  color: #b8b7ad!important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 800px) {
  .mobile-nav-container {
    display: block;
  }
  .bm-burger-button {
    display: block;
  }
}
.Header_headerContainer__-unE5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    max-height: 40px;
    background: #FFF3C3;
}

.Header_logoContainer__2GLMS {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.Header_logoContainer__2GLMS p {
    color: #2F5597;
    /* font-size: 35px; */
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.Header_logoContainer__2GLMS img {
    object-fit: contain;
    max-height: 40px;
}

.Header_navigationContainer__200qo {
    display: flex;
}

.Header_navigationContainer__200qo button {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    font-size: 16px;
    color: #2F5597;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;

}

.Header_landingContainer__12jy_ {
 display: flex;
 flex-direction: column;
 padding: 50px;
 position: relative;
 background: #FFF3C3;
}

.Header_landingContainer__12jy_::after { 
    position:absolute;
    bottom: -50px;
    left: 0px;
    width: 100%;
    content:" ";
    background: 
    radial-gradient(circle at 50% 0%, #FFF3C3 25%, #fff 26%, #fff 40%);
    background-color: #FFF3C3;
    background-size:50px 100px;
    height:50px;
    background-repeat: repeat-x;
  }

.Header_landingImgContainer__32C3h {
    display: flex;
    justify-content: center;
    max-width: 100%;
    height: 300px;
}

.Header_landingImgContainer__32C3h img {
    object-fit: contain;
    max-width: 70%;
}

.Header_landingImgContainer__32C3h p { 
    color: #2F5597;
    /* font-size: 35px; */
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.Header_landingImgContainer__32C3h p {
    display: none;
}

@media (max-width: 800px) {
    .Header_navigationContainer__200qo {
        display: none;
    }

    .Header_landingContainer__12jy_ {
        padding-top: 0px;
    }

    .Header_logoContainer__2GLMS {
        width: 100%;
    }

    .Header_logoContainer__2GLMS img {
        padding-top: 10px;
        max-height: 70px;
    }

    .Header_logoContainer__2GLMS p {
        display: none;
        font-size: 20px;
    }

    .Header_landingImgContainer__32C3h {
        flex-direction: column;
    }

    .Header_landingImgContainer__32C3h p {
        display: flex;
        text-align: center;
        font-size: 24px;
    }

    .Header_landingImgContainer__32C3h img {
        max-width: 100%;
    }
}

.Intro_introContainer__3wmlZ {
    padding: 50px;
    display: flex;
    align-items: center;    /* background-color: #BACDF6; */
    height: 400px;
    margin-top: 35px;
    grid-gap: 30px;
    gap: 30px;
    flex-direction: row;
}

.Intro_messageContainer__1y8ta {
    width: 50%;
}

.Intro_messageContainer__1y8ta img {
    object-fit: contain;
    max-width: 100%;
}

.Intro_messageContainer__1y8ta p {
    font-size: 22px;
    font-weight: bolder;
    color: #fff;
   }

.Intro_textContainer__2sCd9 {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
}

.Intro_textContainer__2sCd9 p {
    padding: 0;
    margin: 0 ;
}

.Intro_goalText__3oBLk {
    font-size: 35px;
    color: #92D050;
    font-weight: 900;
    font-family: 'Josefin Sans', sans-serif;

}

.Intro_descriptionText__rRPNT {
    background-color: #EAF4E1;
    padding: 0 20px;
}

.Intro_descriptionText__rRPNT p {
    margin: 20px 0;
    font-size: 16px;
    color: #597F31;
}

@media (max-width: 800px) {
    .Intro_introContainer__3wmlZ {
      flex-direction: column;
      height: auto;
      padding: 0px;
      margin-top: 60px;
    }

    .Intro_goalText__3oBLk {
        font-size: 30px;
    }

    .Intro_messageContainer__1y8ta {
        width: 80%;
    }

    .Intro_textContainer__2sCd9 {
        width: 80%;
    }
}
.KidsFitness_sectionContainer__1TPeh {
    /* margin-top: 20px; */
    padding: 20px 50px;
    background: #BACDF6;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}

.KidsFitness_sectionTitle__2g75T {
    color: #2F5597;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
}

.KidsFitness_sectionDescription__2wFkf,
.KidsFitness_hiddenText__29fud {
    color: #213C6D;
}

.KidsFitness_buttonContainer__1ir7I button {
    background: #2F5597;
    border: none;
    padding: 10px 12px;
    cursor: pointer;
    outline: none;
    /* text-transform: uppercase; */
    font-size: 16px;
    color: #fff;
    border-radius: 999px;
    box-shadow: #213C6D 0 10px 20px -10px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
}

.KidsFitness_buttonContainer__1ir7I button:hover {
    box-shadow: rgba(33,60,109,.35) 0 -25px 18px -14px inset,rgba(33,60,109,.25) 0 1px 2px,rgba(33,60,109,.25) 0 2px 4px,rgba(33,60,109,.25) 0 4px 8px,rgba(33,60,109,.25) 0 8px 16px,rgba(33,60,109,.25) 0 16px 32px;
    transform: scale(1.05);
}

.KidsFitness_sectionRight__2pXfi {
    width: 50%;
}

.KidsFitness_sectionRight__2pXfi img {
    object-fit: contain;
    max-width: 100%;
}

.KidsFitness_sectionLeft__1I1rO {
    width: 50%;
}

@media (max-width: 800px) {
    .KidsFitness_sectionContainer__1TPeh {
      flex-direction: column;
      height: auto;
      padding: 0px;
      margin-top: 30px;
      padding-bottom: 30px;
    }

    .KidsFitness_sectionLeft__1I1rO {
        width: 80%;
        order: 2;
    }

    .KidsFitness_sectionRight__2pXfi {
        width: 80%;
        order: 1;
        margin-top: 30px;
    }
}
.DanceFitnes_sectionContainer__2dewn {
    /* margin-top: 20px; */
    padding: 20px 50px;
    background: #FFF3C3;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
        /* --mask:
          radial-gradient(64.14px at 50% calc(100% - 88.00px),#000 99%,#0000 101%) calc(50% - 110px) 0/220px 100%,
          radial-gradient(64.14px at 50% calc(100% + 33.00px),#0000 99%,#000 101%) 50% calc(100% - 55px)/220px 100% repeat-x;
        -webkit-mask: var(--mask);
                mask: var(--mask); */
}

.DanceFitnes_rightContainer__1ogUf,
.DanceFitnes_leftContainer__rvqaB {
    width: 50%
}

.DanceFitnes_sectionTitle__2Agg2 p {
    color: #2F5597;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.DanceFitnes_sectionDescription__1echt,
.DanceFitnes_hiddenText__v5TxH {
    color: #213C6D;
}

.DanceFitnes_buttonContainer__3o4PS button {
    background: #FFC000;
    border: none;
    padding: 10px 12px;
    cursor: pointer;
    outline: none;
    /* text-transform: uppercase; */
    font-size: 16px;
    color: #fff;
    border-radius: 999px;
    box-shadow: #FFC000 0 10px 20px -10px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
}

.DanceFitnes_buttonContainer__3o4PS button:hover {
    box-shadow: rgba(255,192,0,.35) 0 -25px 18px -14px inset,rgba(255,192,0,.25) 0 1px 2px,rgba(255,192,0,.25) 0 2px 4px,rgba(255,192,0,.25) 0 4px 8px,rgba(255,192,0,.25) 0 8px 16px,rgba(255,192,0,.25) 0 16px 32px;
    transform: scale(1.05);
}

.DanceFitnes_leftContainer__rvqaB img {
    object-fit: contain;
    max-width: 100%;
}

@media (max-width: 800px) {
    .DanceFitnes_sectionContainer__2dewn {
      flex-direction: column;
      height: auto;
      padding: 0px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .DanceFitnes_rightContainer__1ogUf,
    .DanceFitnes_leftContainer__rvqaB {
        width: 80%
    }
}
.Summary_summaryContainer__2uHLu {
    /* margin-top: 20px; */
    padding: 20px 50px 40px 50px;
    background-color: #EAF4E1;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}

.Summary_listsContainer__T8OlB {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.Summary_bulletsContainer__1SHLf {
    display: flex;
    flex-direction: row;
}

.Summary_rightContainer__3_GKb,
.Summary_middleContainer__1l06K {
    width: 35%;
    /* align-self: start; */
}

.Summary_leftContainer__2yylB {
    width: 30%;
    display: flex;
    justify-content: center;
}

.Summary_title__IbfMw {
    color: #92D050;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.Summary_list__1A9Ml {
    background-color: #EAF4E1;
    padding: 20px;
}

.Summary_list__1A9Ml ul li {
    color: #597F31;
    margin: 10px 0;
    font-size: 16px;
}

.Summary_leftContainer__2yylB img {
    object-fit: contain;
    max-width: 100%;
    padding: 0px 40px;
}

@media (max-width: 800px) {
    .Summary_summaryContainer__2uHLu {
      flex-direction: column;
      height: auto;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 30px;
      grid-gap: 0px;
      gap: 0px;
    }

    .Summary_title__IbfMw {
        padding-left: 20px;
    }

    .Summary_listsContainer__T8OlB {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .Summary_leftContainer__2yylB {
        margin: 0 auto;
    }

    .Summary_bulletsContainer__1SHLf {
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .Summary_middleContainer__1l06K,
    .Summary_rightContainer__3_GKb {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .Summary_titleAndIcon__UVKo7 p {
        margin: 0px;
    }

    .Summary_list__1A9Ml {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .Summary_list__1A9Ml ul {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    .Summary_list__1A9Ml ul li:last-child {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
.Extras_extrasContainer__vqc3G {
    display: flex;
    background: #BACDF6;
    padding: 40px 50px;
    grid-gap: 20px;
    gap: 20px;
    align-items: top;
    justify-content: space-evenly;
}

.Extras_leftContainer__3U4ha,
.Extras_rightContainer__oYHVv {
    width: 50%;
}

.Extras_titleAndIcon__37soo {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: end;
}

.Extras_titleAndIcon__37soo p {
    color: #2F5597;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    /* margin: 0; */
}

.Extras_titleAndIcon__37soo img {
    max-height: 100px;
}

.Extras_playDescription__qH3sD p {
    color:#2F5597;
}

.Extras_playDescription__qH3sD ul li {
    color:#2F5597;

}

@media (max-width: 800px) {
    .Extras_extrasContainer__vqc3G {
      flex-direction: column;
      height: auto;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .Extras_rightContainer__oYHVv,
    .Extras_leftContainer__3U4ha {
        width: 100%
    }

    .Extras_titleAndIcon__37soo p {
        margin: 0px;
    }
}
.Gallery_galleryContainer__2ruuB {
    position: relative;
    background: #fff;
    padding: 15px 50px 50px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Gallery_galleryContainer__2ruuB::after { 
    position:absolute;
    bottom: -50px;
    left: 0px;
    width: 100%;
    content:" ";
    background: 
    radial-gradient(circle at 50% 0%, #fff 25%, #FFF3C3 26%, #FFF3C3 40%);
    background-color: #fff;
    background-size:50px 100px;
    height:50px;
    background-repeat: repeat-x;
  }

  .Gallery_galleryContent__S2uuz {
    display: flex;
    /* width: 100%; */
    justify-content: space-evenly;
    grid-gap: 20px 20px ;
    gap: 20px 20px ;
    flex-wrap: wrap;
  }

  .Gallery_galleryWrap__ae4jq {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-evenly;
    width: 100%;
  }

  .Gallery_title__1WhNU p {
    color: #92D050;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.Gallery_galleryContent__S2uuz .Gallery_image__dZ8Fi {
  width: 22%;
  object-fit: cover;
}

@media (max-width: 800px) {
  .Gallery_galleryContent__S2uuz .Gallery_image__dZ8Fi {
    width: 40%;
    object-fit: cover;
  }
}
.Contact_contactContainer__2-H-E {
    display: flex;
    flex-direction: column;
    background: #FFF3C3;    
    padding: 40px 50px;
    /* gap: 20px; */
    align-items: center;
}

.Contact_infoContainer__2zfn4 {
    display: flex;
    width: 100%;
    padding-top: 20px;
    grid-gap: 20px;
    gap: 20px;
    /* align-items: flex-start; */
}

.Contact_leftContainer__3Aqi4,
.Contact_rightContainer__3pEkH,
.Contact_middleContainer__3bGwO {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Contact_leftContainer__3Aqi4 p,
.Contact_rightContainer__3pEkH p,
.Contact_middleContainer__3bGwO p {
    color: #2F5597;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
}

.Contact_leftContainer__3Aqi4 img,
.Contact_rightContainer__3pEkH img,
.Contact_middleContainer__3bGwO img {
    width: 50px;
    height: 50px;
}

/* .leftContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
} */

.Contact_title__16YNM p {
    color: #C89702;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.Contact_title__16YNM {
    color: #213C6D;
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}


/* .rightContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
} */


@media (max-width: 800px) {
    .Contact_contactContainer__2-H-E {
        margin-bottom: 25px;
      }

    .Contact_infoContainer__2zfn4 {
      flex-direction: column;
      height: auto;
      padding: 0px;
      margin-bottom: 30px;
    }

    .Contact_rightContainer__3pEkH,
    .Contact_middleContainer__3bGwO,
    .Contact_leftContainer__3Aqi4 {
        width: 100%;
        flex-direction: row;
    }
}
