.galleryContainer {
    position: relative;
    background: #fff;
    padding: 15px 50px 50px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.galleryContainer::after { 
    position:absolute;
    bottom: -50px;
    left: 0px;
    width: 100%;
    content:" ";
    background: 
    radial-gradient(circle at 50% 0%, #fff 25%, #FFF3C3 26%, #FFF3C3 40%);
    background-color: #fff;
    background-size:50px 100px;
    height:50px;
    background-repeat: repeat-x;
  }

  .galleryContent {
    display: flex;
    /* width: 100%; */
    justify-content: space-evenly;
    gap: 20px 20px ;
    flex-wrap: wrap;
  }

  .galleryWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-evenly;
    width: 100%;
  }

  .title p {
    color: #92D050;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.galleryContent .image {
  width: 22%;
  object-fit: cover;
}

@media (max-width: 800px) {
  .galleryContent .image {
    width: 40%;
    object-fit: cover;
  }
}