.contactContainer {
    display: flex;
    flex-direction: column;
    background: #FFF3C3;    
    padding: 40px 50px;
    /* gap: 20px; */
    align-items: center;
}

.infoContainer {
    display: flex;
    width: 100%;
    padding-top: 20px;
    gap: 20px;
    /* align-items: flex-start; */
}

.leftContainer,
.rightContainer,
.middleContainer {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.leftContainer p,
.rightContainer p,
.middleContainer p {
    color: #2F5597;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
}

.leftContainer img,
.rightContainer img,
.middleContainer img {
    width: 50px;
    height: 50px;
}

/* .leftContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
} */

.title p {
    color: #C89702;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.title {
    color: #213C6D;
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}


/* .rightContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
} */


@media (max-width: 800px) {
    .contactContainer {
        margin-bottom: 25px;
      }

    .infoContainer {
      flex-direction: column;
      height: auto;
      padding: 0px;
      margin-bottom: 30px;
    }

    .rightContainer,
    .middleContainer,
    .leftContainer {
        width: 100%;
        flex-direction: row;
    }
}