.sectionContainer {
    /* margin-top: 20px; */
    padding: 20px 50px;
    background: #FFF3C3;
    display: flex;
    gap: 20px;
    align-items: center;
        /* --mask:
          radial-gradient(64.14px at 50% calc(100% - 88.00px),#000 99%,#0000 101%) calc(50% - 110px) 0/220px 100%,
          radial-gradient(64.14px at 50% calc(100% + 33.00px),#0000 99%,#000 101%) 50% calc(100% - 55px)/220px 100% repeat-x;
        -webkit-mask: var(--mask);
                mask: var(--mask); */
}

.rightContainer,
.leftContainer {
    width: 50%
}

.sectionTitle p {
    color: #2F5597;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.sectionDescription,
.hiddenText {
    color: #213C6D;
}

.buttonContainer button {
    background: #FFC000;
    border: none;
    padding: 10px 12px;
    cursor: pointer;
    outline: none;
    /* text-transform: uppercase; */
    font-size: 16px;
    color: #fff;
    border-radius: 999px;
    box-shadow: #FFC000 0 10px 20px -10px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
}

.buttonContainer button:hover {
    box-shadow: rgba(255,192,0,.35) 0 -25px 18px -14px inset,rgba(255,192,0,.25) 0 1px 2px,rgba(255,192,0,.25) 0 2px 4px,rgba(255,192,0,.25) 0 4px 8px,rgba(255,192,0,.25) 0 8px 16px,rgba(255,192,0,.25) 0 16px 32px;
    transform: scale(1.05);
}

.leftContainer img {
    object-fit: contain;
    max-width: 100%;
}

@media (max-width: 800px) {
    .sectionContainer {
      flex-direction: column;
      height: auto;
      padding: 0px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .rightContainer,
    .leftContainer {
        width: 80%
    }
}