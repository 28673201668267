.introContainer {
    padding: 50px;
    display: flex;
    align-items: center;    /* background-color: #BACDF6; */
    height: 400px;
    margin-top: 35px;
    gap: 30px;
    flex-direction: row;
}

.messageContainer {
    width: 50%;
}

.messageContainer img {
    object-fit: contain;
    max-width: 100%;
}

.messageContainer p {
    font-size: 22px;
    font-weight: bolder;
    color: #fff;
   }

.textContainer {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.textContainer p {
    padding: 0;
    margin: 0 ;
}

.goalText {
    font-size: 35px;
    color: #92D050;
    font-weight: 900;
    font-family: 'Josefin Sans', sans-serif;

}

.descriptionText {
    background-color: #EAF4E1;
    padding: 0 20px;
}

.descriptionText p {
    margin: 20px 0;
    font-size: 16px;
    color: #597F31;
}

@media (max-width: 800px) {
    .introContainer {
      flex-direction: column;
      height: auto;
      padding: 0px;
      margin-top: 60px;
    }

    .goalText {
        font-size: 30px;
    }

    .messageContainer {
        width: 80%;
    }

    .textContainer {
        width: 80%;
    }
}